import React from "react";
import styled from "styled-components";
const Svg = styled.svg`
  height: 1em;
`;
export const PreparingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M112 0c-8.8 0-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16c0-8.8-7.2-16-16-16zM256 352H192c-88.4 0-160-71.6-160-160H416c0 88.4-71.6 160-160 160zM32 160c-17.7 0-32 14.3-32 32c0 80.5 49.5 149.4 119.7 177.9l-7.4 16.2c-5.2-1.4-10.7-2.1-16.4-2.1c-35.3 0-64 28.7-64 64s28.7 64 64 64c29.8 0 54.9-20.4 62-48H335.9l17.6 38.6c3.7 8 13.1 11.6 21.2 7.9s11.6-13.1 7.9-21.2L328.3 369.9C398.5 341.4 448 272.5 448 192c0-17.7-14.3-32-32-32H32zM140.4 401.9l10.2-22.4c13.4 2.9 27.2 4.5 41.5 4.5h64c14.2 0 28.1-1.5 41.5-4.5L321.3 432H158c-3-11.7-9.2-22-17.6-30.1zM64 448a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM224 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16z"
    />
  </Svg>
);
export const DefaultIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
  </Svg>
);
export const DeletedIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zM331.3 235.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 329.4 308.7 212.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
  </Svg>
);
export const ArrowLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" fill="currentColor">
    <path fill="currentColor" d="M2.20425 4.9992L5.91675 8.7117L4.85625 9.7722L0.083252 4.9992L4.85625 0.226196L5.91675 1.2867L2.20425 4.9992Z" />
  </Svg>
);
export const ArrowDoubleLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
    <path fill="currentColor" d="M0.0249023 5.00057L4.33536 9.31106L5.31751 8.32897L1.98908 5.00057L5.31751 1.67215L4.33536 0.690063L0.0249023 5.00057ZM3.94842 5.00057L8.2589 9.31106L9.24106 8.32897L5.91265 5.00057L9.24106 1.67215L8.2589 0.690063L3.94842 5.00057Z" />
  </Svg>
);
export const ArrowRightIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
    <path fill="currentColor" d="M3.796 4.9992L0.0834961 1.2867L1.144 0.226196L5.917 4.9992L1.144 9.7722L0.0834961 8.7117L3.796 4.9992Z" />
  </Svg>
);
export const ArrowRightDoubleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="arrow-right-double-icon">
    <path d="M9.97541 5.00057L5.66492 0.690063L4.68284 1.67215L8.01124 5.00057L4.68284 8.32897L5.66492 9.31106L9.97541 5.00057ZM6.05187 5.00057L1.74137 0.690063L0.759277 1.67215L4.0877 5.00057L0.759277 8.32897L1.74137 9.31106L6.05187 5.00057Z" fill="currentColor" />
  </Svg>
);
export const BackIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16" fill="none">
    <path fill="currentColor" d="M3.371 5.24998L5.273 7.15198L4.2125 8.21247L0.5 4.49998L4.2125 0.787476L5.273 1.84798L3.371 3.74998H8.75C10.3413 3.74998 11.8674 4.38212 12.9926 5.50733C14.1179 6.63255 14.75 8.15868 14.75 9.74998C14.75 11.3413 14.1179 12.8674 12.9926 13.9926C11.8674 15.1178 10.3413 15.75 8.75 15.75H2V14.25H8.75C9.94347 14.25 11.0881 13.7759 11.932 12.932C12.7759 12.088 13.25 10.9434 13.25 9.74998C13.25 8.5565 12.7759 7.41191 11.932 6.56799C11.0881 5.72408 9.94347 5.24998 8.75 5.24998H3.371Z" />
  </Svg>
);
export const PenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
    <path fill="currentColor" d="M2.77717 7.10093H7.98516V7.98686H0.0117188V6.10735L4.39711 1.72196L6.27618 3.60192L2.77673 7.10093H2.77717ZM5.02302 1.09604L5.963 0.156062C6.04607 0.0730187 6.15872 0.0263672 6.27618 0.0263672C6.39364 0.0263672 6.50629 0.0730187 6.58936 0.156062L7.84252 1.40922C7.92556 1.49229 7.97222 1.60494 7.97222 1.7224C7.97222 1.83986 7.92556 1.95251 7.84252 2.03558L6.90254 2.97512L5.02347 1.09604H5.02302Z" />
  </Svg>
);

export const RedeemCouponIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path fill="currentColor" d="M32 128c0-17.7 14.3-32 32-32l512 0c17.7 0 32 14.3 32 32l0 49.6c-36.5 7.4-64 39.7-64 78.4s27.5 71 64 78.4l0 49.6c0 17.7-14.3 32-32 32L64 416c-17.7 0-32-14.3-32-32l0-256zM64 64C28.7 64 0 92.7 0 128L0 384c0 35.3 28.7 64 64 64l512 0c35.3 0 64-28.7 64-64l0-66.4c0-8-8-13.6-16-13.6c-26.5 0-48-21.5-48-48s21.5-48 48-48c8 0 16-5.6 16-13.6l0-66.4c0-35.3-28.7-64-64-64L64 64zm416 96a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm0 64a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm-16 80a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm16 48a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z" />
  </Svg>
);
export const WeekMenuIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 48L64 64C28.7 64 0 92.7 0 128l0 32 0 32L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-32 0-32c0-35.3-28.7-64-64-64l-32 0 0-48c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 48L128 64l0-48zM32 192l384 0 0 256c0 17.7-14.3 32-32 32L64 480c-17.7 0-32-14.3-32-32l0-256zM64 96l320 0c17.7 0 32 14.3 32 32l0 32L32 160l0-32c0-17.7 14.3-32 32-32zm40 160l240 0c4.4 0 8 3.6 8 8l0 80c0 4.4-3.6 8-8 8l-240 0c-4.4 0-8-3.6-8-8l0-80c0-4.4 3.6-8 8-8zm-40 8l0 80c0 22.1 17.9 40 40 40l240 0c22.1 0 40-17.9 40-40l0-80c0-22.1-17.9-40-40-40l-240 0c-22.1 0-40 17.9-40 40z"
    />
  </Svg>
);
export const RecipesIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M170.9 83.4C187 52.8 219.1 32 256 32s69 20.8 85 51.4c2.5 4.7 7.1 7.9 12.4 8.5s10.5-1.5 13.9-5.5C379.1 72.7 396.5 64 416 64c35.3 0 64 28.7 64 64c0 4-.4 7.8-1 11.6l-.1 .5c-3.5 22.8-14.2 58.4-26.1 93.5c-11.8 34.7-24.3 67.7-31 84.7c-.4 1-1.4 1.8-2.8 1.8l-45.2 0 25.9-141.1c1.6-8.7-4.2-17-12.8-18.6s-17 4.2-18.6 12.8l-26.4 144c-.2 1-.3 1.9-.3 2.9L272 320l0-176c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 176-69.6 0c0-1-.1-1.9-.3-2.9l-26.4-144c-1.6-8.7-9.9-14.4-18.6-12.8s-14.4 9.9-12.8 18.6L138.2 320 93 320c-1.4 0-2.4-.8-2.8-1.8c-6.6-17-19.2-50-31-84.7C47.3 198.4 36.6 162.8 33.1 140l-.1-.5c-.7-3.7-1-7.6-1-11.6c0-35.3 28.7-64 64-64c19.5 0 36.9 8.7 48.6 22.4c3.5 4 8.7 6.1 13.9 5.5s9.9-3.8 12.4-8.5zM256 0c-41.9 0-79.1 20.1-102.4 51.2c-16-12-36-19.2-57.6-19.2C43 32 0 75 0 128c0 5.8 .5 11.5 1.5 17.1c4 25.9 15.6 63.8 27.4 98.7c12 35.3 24.7 68.8 31.4 86C65.6 343.4 78.7 352 93 352l326 0c14.3 0 27.4-8.6 32.6-22.2c6.7-17.2 19.4-50.7 31.4-86c11.8-34.9 23.4-72.8 27.4-98.7c1-5.5 1.5-11.2 1.5-17.1c0-53-43-96-96-96c-21.6 0-41.6 7.2-57.6 19.2C335.1 20.1 297.9 0 256 0zM64 384l0 64c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-64-32 0 0 64c0 17.7-14.3 32-32 32l-256 0c-17.7 0-32-14.3-32-32l0-64-32 0z"
    />
  </Svg>
);
export const RecipeCategoryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M144 256a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm143.1-96c-8 72-69 128-143.1 128C64.5 288 0 223.5 0 144S64.5 0 144 0c74.1 0 135.2 56 143.1 128l67.6 0c6.6-18.6 24.4-32 45.3-32l96 0c26.5 0 48 21.5 48 48s-21.5 48-48 48l-96 0c-20.9 0-38.7-13.4-45.3-32l-67.6 0zM272 320l16 0 32 0 0-32c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32l0 32 32 0 16 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-16 0 0 112c0 26.5-21.5 48-48 48l-128 0c-26.5 0-48-21.5-48-48l0-112-16 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm80 0l96 0 0-32-96 0 0 32zm-32 32l0 112c0 8.8 7.2 16 16 16l128 0c8.8 0 16-7.2 16-16l0-112-160 0zM496 128l-96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16zM192 144a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM64 144a80 80 0 1 1 160 0A80 80 0 1 1 64 144zM0 496c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L16 512c-8.8 0-16-7.2-16-16zM160 352l0 32 16 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-16 0zm0-32l16 0c26.5 0 48 21.5 48 48s-21.5 48-48 48l-16 0c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-80c0-8.8 7.2-16 16-16l80 0 16 0 16 0zm-32 64l0-32-64 0 0 64 64 0 0-32z"
    />
  </Svg>
);
export const CuisineCategoryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M16 16C7.2 16 0 23.2 0 32L0 176c0 26.5 21.5 48 48 48l16 0 0 256c0 8.8 7.2 16 16 16s16-7.2 16-16l0-256 16 0c26.5 0 48-21.5 48-48l0-144c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 144c0 8.8-7.2 16-16 16l-16 0L96 32c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 160-16 0c-8.8 0-16-7.2-16-16L32 32c0-8.8-7.2-16-16-16zM498 78.9C455.3 39.8 398.4 16 336 16c-54 0-103.9 17.9-144 48l0 41.9C229.4 70 280.1 48 336 48c58.9 0 112 24.5 149.9 63.8c3.1-12 7.2-22.9 12.1-32.9zm24.5 269.4C488.5 416.9 417.7 464 336 464c-73.8 0-138.6-38.4-175.5-96.4c-8.5 6.5-18.3 11.3-29 14C173.7 450.2 249.5 496 336 496c98.4 0 183-59.2 220-144l-12 0c-7.6 0-14.8-1.3-21.5-3.7zM480 256a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-256 0a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zM608 54.4L608 288l-64 0 0-128c0-61.3 39.4-92.2 64-105.6zM640 288l0-251.1c0-11.5-9.3-20.9-20.9-20.9c-2.1 0-4.1 .3-6.1 1C596.2 23.2 512 59.2 512 160l0 128c0 17.7 14.3 32 32 32l64 0 0 160c0 8.8 7.2 16 16 16s16-7.2 16-16l0-160 0-16 0-16z"
    />
  </Svg>
);
export const CalorieManagementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M224 112l0 5.3c-18.6-6.6-32-24.4-32-45.3l0-5.3c18.6 6.6 32 24.4 32 45.3zM160 48l0 24c0 44.2 35.8 80 80 80c8.8 0 16-7.2 16-16l0-24c0-44.2-35.8-80-80-80c-8.8 0-16 7.2-16 16zM48 64c-8.8 0-16 7.2-16 16s7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L48 64zM16 144c-8.8 0-16 7.2-16 16s7.2 16 16 16l128 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L16 144zm16 96c0 8.8 7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zM336 117.3c-18.6-6.6-32-24.4-32-45.3l0-5.3c18.6 6.6 32 24.4 32 45.3l0 5.3zM288 32c-8.8 0-16 7.2-16 16l0 24c0 44.2 35.8 80 80 80c8.8 0 16-7.2 16-16l0-24c0-44.2-35.8-80-80-80zm160 80l0 5.3c-18.6-6.6-32-24.4-32-45.3l0-5.3c18.6 6.6 32 24.4 32 45.3zM384 48l0 24c0 44.2 35.8 80 80 80c8.8 0 16-7.2 16-16l0-24c0-44.2-35.8-80-80-80c-8.8 0-16 7.2-16 16zM304 253.3l0-5.3c0-20.9 13.4-38.7 32-45.3l0 5.3c0 20.9-13.4 38.7-32 45.3zM352 168c-44.2 0-80 35.8-80 80l0 24c0 8.8 7.2 16 16 16c44.2 0 80-35.8 80-80l0-24c0-8.8-7.2-16-16-16zM192 248c0-20.9 13.4-38.7 32-45.3l0 5.3c0 20.9-13.4 38.7-32 45.3l0-5.3zm64-64c0-8.8-7.2-16-16-16c-44.2 0-80 35.8-80 80l0 24c0 8.8 7.2 16 16 16c44.2 0 80-35.8 80-80l0-24zm160 69.3l0-5.3c0-20.9 13.4-38.7 32-45.3l0 5.3c0 20.9-13.4 38.7-32 45.3zM464 168c-44.2 0-80 35.8-80 80l0 24c0 8.8 7.2 16 16 16c44.2 0 80-35.8 80-80l0-24c0-8.8-7.2-16-16-16zM129.8 441.1c-5.5-9.5-15.5-15.6-26.5-16C64 423.6 32.5 391.5 32 352l448 0c-.5 39.5-32 71.6-71.3 73.1c-11 .4-21 6.5-26.5 16c-2.4 4.2-6.9 6.9-11.9 6.9l-228.6 0c-5 0-9.5-2.7-11.9-6.9zM31 320c-17.1 0-31 13.9-31 31c0 57.3 45.3 103.9 102.1 106.1c7.9 13.7 22.7 22.9 39.6 22.9l228.6 0c17 0 31.8-9.2 39.6-22.9C466.7 454.9 512 408.2 512 351c0-17.1-13.9-31-31-31L31 320z"
    />
  </Svg>
);
export const FoodExchangeServeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M426.1 301.2C406.2 376.5 337.6 432 256 432c-51 0-96.9-21.7-129-56.3l41-41c5.1-5.1 8-12.1 8-19.3c0-15.1-12.2-27.3-27.3-27.3L48 288c-8.8 0-16 7.2-16 16l0 100.7C32 419.8 44.2 432 59.3 432c7.2 0 14.2-2.9 19.3-8l25.7-25.7C142.3 438.7 196.2 464 256 464c97.4 0 179.2-67 201.8-157.4c2.4-9.7-5.2-18.6-15.2-18.6c-7.8 0-14.5 5.6-16.5 13.2zM385 136.3l-41 41c-5.1 5.1-8 12.1-8 19.3c0 15.1 12.2 27.3 27.3 27.3L464 224c8.8 0 16-7.2 16-16l0-100.7C480 92.2 467.8 80 452.7 80c-7.2 0-14.2 2.9-19.3 8l-25.7 25.7C369.7 73.3 315.8 48 256 48C158.6 48 76.8 115 54.2 205.4c-2.4 9.7 5.2 18.6 15.2 18.6c7.8 0 14.5-5.6 16.5-13.2C105.8 135.5 174.4 80 256 80c51 0 96.9 21.7 129.1 56.3zM448 192l-73.4 0L448 118.6l0 73.4zM64 320l73.4 0L64 393.4 64 320z"
    />
  </Svg>
);
export const FoodExchangeCalorieIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M443.3 139.3c6.2-6.2 6.2-16.4 0-22.6l-96-96c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 112 16 112c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0-68.7 68.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96zm-342.6 352c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 400 432 400c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 368l68.7-68.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6l96 96z" />
  </Svg>
);
export const MealPlansIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M496 64c0-17.7 14.3-32 32-32s32 14.3 32 32c0 2.7-.3 5.4-1 7.9c-1.3 5.2-.5 11.1 2.9 16c3.7 5.2 9.4 8 15.2 8.2c17.1 .6 30.9 14.7 30.9 32c0 17.7-14.3 32-32 32c-6 0-12.6-.6-20.3-1.5l-3.9-.5c-6.3-.7-13.2-1.5-19.9-2c-15.9-1-35.4-.4-51.5 11l-41.6 29.3c-18.8 13.2-30.5 32-39.1 50.6c-5.3 11.3-10 24.3-14.4 36.2c-2.5 6.7-4.8 13.1-7.1 18.8c-4.9 12.2-12.9 23.3-23.9 32.2C320 362 269.6 356.7 241.8 322.3S219.3 237.6 253.7 209.8c17.2-13.9 38.3-19.5 58.7-17.4c14.5 1.5 31.2 3.1 47.6 2c16.5-1.2 33.7-5.3 48.9-16l63.6-44.9c12.8-9 18.4-22.7 20.9-34.8C495.9 86.7 496 73.8 496 64zM528 0c-35.3 0-64 28.7-64 64c0 10.1-.2 19.7-1.9 28.1c-1.8 8.3-4.6 12.9-8.1 15.3l-40.3 28.4C370.6 110.5 325.7 96 288 96C176 96 0 224 0 384C0 512 160 512 288 512s288 0 288-128c0-60.3-25-116.1-62.2-162.2l-26.2 18.5C522 282.3 544 332 544 384c0 24.6-7.4 40.5-18.3 52c-11.6 12.3-29.6 22.1-54.5 29.2C420.4 479.7 353.4 480 288 480s-132.4-.3-183.2-14.8c-24.9-7.1-42.9-16.9-54.5-29.2C39.4 424.5 32 408.6 32 384c0-68 37.6-132 91.4-180.2C177.6 155.2 242.3 128 288 128c27.3 0 61.5 9.7 96.1 28c-7.6 3.8-16.4 5.8-26.5 6.5c-13.1 .9-27.2-.3-41.9-1.9c-28.5-3-58.2 4.9-82.2 24.3c-48.1 38.9-55.5 109.4-16.6 157.5s109.4 55.5 157.5 16.6c15.3-12.4 26.5-28 33.4-45.1c3.1-7.7 5.7-14.9 8.3-21.9c0 0 0 0 0 0c4-10.8 7.7-21.1 12.6-31.6c7.6-16.4 16.3-29.3 28.5-37.9l41.6-29.3c6.7-4.7 16.5-6.2 31-5.2c5.8 .4 11.7 1.1 18 1.8l4.3 .5c7.6 .9 15.9 1.7 23.9 1.7c35.3 0 64-28.7 64-64c0-29.8-20.4-54.9-48-62c0-.7 0-1.3 0-2c0-35.3-28.7-64-64-64z"
    />
  </Svg>
);
export const IngredientIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 25.8 4.3 44.4 11.7 59.2c7.2 14.3 16.7 23.9 24.6 31.7l.4 .4c8.1 8.1 14.3 14.4 19 23.8c4.6 9.2 8.3 22.7 8.3 44.8c0 8.8 7.2 16 16 16s16-7.2 16-16c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-.4-.4c-8.1-8.1-14.3-14.4-19-23.8C131.7 51.6 128 38.2 128 16zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 25.8 4.3 44.4 11.7 59.2c7.2 14.3 16.7 23.9 24.6 31.7c0 0 0 0 0 0s0 0 0 0s0 0 0 0l.4 .4c8.1 8.1 14.3 14.4 19 23.8c4.6 9.2 8.3 22.7 8.3 44.8c0 8.8 7.2 16 16 16s16-7.2 16-16c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-.4-.4c-8.1-8.1-14.3-14.4-19-23.8C227.7 51.6 224 38.2 224 16zM32 256c-8.8 0-16 7.2-16 16s7.2 16 16 16l35.8 0c5.9 82.9 56.5 152.7 127.3 186.4c7.6 21.9 28.4 37.6 52.9 37.6l144 0c24.5 0 45.3-15.7 52.9-37.6C515.7 440.7 566.3 370.9 572.2 288l35.8 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-37.6 0c-6.6-18.6-24.3-32-45.3-32l-410.2 0c-21 0-38.7 13.4-45.3 32L32 256zm507.4-64c-13.8-46.3-56.6-80-107.4-80s-93.6 33.7-107.4 80l34 0c12.3-28.3 40.5-48 73.3-48s61 19.7 73.3 48l34 0zM225.3 463.9c-2.8-8.1-8.7-14.7-16.5-18.4c-61.4-29.2-105-90.1-109.3-162.2l-.6-10.3c-.5-9.2 6.8-16.9 16-16.9l410.2 0c9.2 0 16.5 7.7 16 16.9l-.6 10.3c-4.2 72.1-47.8 133-109.3 162.2c-7.7 3.7-13.7 10.3-16.5 18.4c-3.3 9.4-12.2 16.1-22.7 16.1l-144 0c-10.4 0-19.4-6.7-22.7-16.1z"
    />
  </Svg>
);
export const OrderManagementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144L0 432c0 44.2 35.8 80 80 80l288 0c44.2 0 80-35.8 80-80l0-128c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 128c0 26.5-21.5 48-48 48L80 480c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48l128 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L80 64z"
    />
  </Svg>
);
export const DispatchSettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M64 32C46.3 32 32 46.3 32 64l0 288c0 17.7 14.3 32 32 32l5.5 0c13.2-37.3 48.7-64 90.5-64s77.4 26.7 90.5 64L384 384l0-320c0-17.7-14.3-32-32-32L64 32zm0 384c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L352 0c35.3 0 64 28.7 64 64l0 32 65.3 0c14 0 27.3 6.1 36.4 16.8l78.7 91.8c7.5 8.7 11.6 19.8 11.6 31.2L608 384l16 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0c0 53-43 96-96 96s-96-43-96-96l-128 0c0 53-43 96-96 96s-96-43-96-96zM570.9 224l-77.5-90.4c-3-3.5-7.5-5.6-12.1-5.6L416 128l0 96 154.9 0zM416 256l0 88.4c17-15.2 39.4-24.4 64-24.4c41.8 0 77.4 26.7 90.5 64l5.5 0 0-128-160 0zM224 416A64 64 0 1 0 96 416a64 64 0 1 0 128 0zm256 64a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM288 224l0-96 0-30.7c-18.4 4.7-32 21.4-32 41.3l0 85.3 32 0zm0 80l0-48-32 0c-17.7 0-32-14.3-32-32l0-85.3C224 97.4 257.4 64 298.7 64c11.8 0 21.3 9.6 21.3 21.3l0 42.7 0 96 0 32 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16zM160 206.1l0 65.9c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-65.9c-31.6-7.6-54.4-37.2-52-71.1l4-56.1C80.7 70 88.3 63.4 97.1 64s15.4 8.3 14.8 17.1l-4 56.1C106.5 158.2 123 176 144 176s37.5-17.8 36.1-38.7l-4-56.1c-.6-8.8 6-16.5 14.8-17.1s16.5 6 17.1 14.8l4 56.1c2.4 33.9-20.4 63.5-52 71.1zM160 80l0 64c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16s16 7.2 16 16z"
    />
  </Svg>
);
export const InventoryManagementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M64 480l194.6 0-1.1 4.5c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L220.1 0c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9l0 121.8-32 32L352 192l-112 0c-26.5 0-48-21.5-48-48l0-112L64 32C46.3 32 32 46.3 32 64l0 384c0 17.7 14.3 32 32 32zM351.5 160c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2L224 144c0 8.8 7.2 16 16 16l111.5 0zm167 104c-6.2-6.2-16.4-6.2-22.6 0l-24.8 24.8 39.9 39.9 24.8-24.8c6.2-6.2 6.2-16.4 0-22.6L518.6 264zM342.4 417.5c-2.1 2.1-3.5 4.6-4.2 7.4L326 473.9l48.9-12.2c2.8-.7 5.4-2.2 7.4-4.2L488.5 351.3l-39.9-39.9L342.4 417.5zM473.3 241.4c18.7-18.7 49.1-18.7 67.9 0l17.3 17.3c18.7 18.7 18.7 49.1 0 67.9L405 480.1c-6.2 6.2-13.9 10.5-22.3 12.6l-74.8 18.7c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l18.7-74.8c2.1-8.4 6.5-16.2 12.6-22.3L473.3 241.4z"
    />
  </Svg>
);
export const UserLogIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      fill="currentColor"
      d="M320 480l-16 0 0-16c0-44.2-35.8-80-80-80l-64 0c-44.2 0-80 35.8-80 80l0 16-16 0c-17.7 0-32-14.3-32-32L32 64c0-17.7 14.3-32 32-32l128 0 0 112c0 26.5 21.5 48 48 48l112 0 0 256c0 17.7-14.3 32-32 32zm-48-16l0 16-160 0 0-16c0-26.5 21.5-48 48-48l64 0c26.5 0 48 21.5 48 48zM240 160c-8.8 0-16-7.2-16-16l0-111.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4L240 160zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-284.1c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1L64 0zM192 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"
    />
  </Svg>
);
export const ActivityLogIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M32 132l0-84c0-8.8-7.2-16-16-16S0 39.2 0 48L0 176c0 8.8 7.2 16 16 16l128 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-90.4 0C89.5 84.3 166.7 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-73.3 0-138.3-35.2-179.2-89.6c-5.3-7.1-15.3-8.5-22.4-3.2s-8.5 15.3-3.2 22.4C97.9 471.8 172.2 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C159.6 0 75.7 53.3 32 132zm224-4c-8.8 0-16 7.2-16 16l0 112c0 4.2 1.7 8.3 4.7 11.3l80 80c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L272 249.4 272 144c0-8.8-7.2-16-16-16z" />
  </Svg>
);
export const BookAppointmentIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M192 464c0 8.8-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16l0-48 96 0 0 48zm-16 48c12.3 0 23.5-4.6 32-12.2c8.5 7.6 19.7 12.2 32 12.2l64 0c26.5 0 48-21.5 48-48l0-48 0-16 0-16 0-190.2 51.4 198.1 4 15.5 18 69.2c6.6 25.5 32 40.6 56.7 33.8l59.6-16.5c24.7-6.8 39.3-33 32.7-58.5l-13.9-53.7-4-15.5-63.9-246-4-15.5-18-69.2C464 9.9 438.6-5.2 413.9 1.6L354.3 18.1c-3.5 1-6.8 2.3-9.9 4C335.9 8.8 321 0 304 0L240 0c-12.3 0-23.5 4.6-32 12.2C199.5 4.6 188.3 0 176 0L112 0C85.5 0 64 21.5 64 48l0 48 0 16 0 16 32 0 80 0 16 0 0 48 32 0 0-48 96 0 0 256-96 0 0-48-32 0 0 48-16 0-80 0-32 0 0 16 0 16 0 48c0 26.5 21.5 48 48 48l64 0zM352 64.8l0-1.3c.3-7.2 5.1-13 10.8-14.6l59.6-16.5c6.6-1.8 14.8 2 17.2 11l14.1 54.2-87.3 24.2L352.6 68.6c-.3-1.3-.5-2.6-.6-3.8zm-32-.6c0 0 0 0 0 0L320 96l-96 0 0-48c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16l0 16.2zM240 480c-8.8 0-16-7.2-16-16l0-48 96 0 0 48c0 8.8-7.2 16-16 16l-64 0zM192 48l0 48L96 96l0-48c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16zm269.7 80.7l59.8 230.5-87.3 24.2L374.4 152.8l87.3-24.2zm67.9 261.5l13.8 53.2c2.4 9.4-3.2 17.7-10.3 19.6l-59.6 16.5c-6.6 1.8-14.8-2-17.2-11l-14.1-54.2 87.3-24.2zM80 160c-17.7 0-32 14.3-32 32l0 16-16 0c-17.7 0-32 14.3-32 32l0 32c0 17.7 14.3 32 32 32l16 0 0 16c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-16 16 0c17.7 0 32-14.3 32-32l0-32c0-17.7-14.3-32-32-32l-16 0 0-16c0-17.7-14.3-32-32-32l-32 0zm0 32l32 0 0 32c0 8.8 7.2 16 16 16l32 0 0 32-32 0c-8.8 0-16 7.2-16 16l0 32-32 0 0-32c0-8.8-7.2-16-16-16l-32 0 0-32 32 0c4.2 0 8.3-1.7 11.3-4.7s4.7-7.1 4.7-11.3l0-32z"
    />
  </Svg>
);
export const MessageSettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M202.7 288L352 288c17.7 0 32-14.3 32-32l0-192c0-17.7-14.3-32-32-32L64 32C46.3 32 32 46.3 32 64l0 192c0 17.7 14.3 32 32 32l32 0c17.7 0 32 14.3 32 32l0 16 55.5-41.6c5.5-4.2 12.3-6.4 19.2-6.4zM352 320l-149.3 0-81.1 60.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3l0-16 0-32-32 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L352 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64zM320 448c-35.3 0-64-28.7-64-64l0-32 32 0 0 32c0 17.7 14.3 32 32 32l117.3 0c6.9 0 13.7 2.2 19.2 6.4L512 464l0-16c0-17.7 14.3-32 32-32l32 0c17.7 0 32-14.3 32-32l0-192c0-17.7-14.3-32-32-32l-128 0 0-32 128 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64l-32 0 0 32 0 16c0 6.1-3.4 11.6-8.8 14.3s-11.9 2.1-16.8-1.5L437.3 448 320 448z"
    />
  </Svg>
);
export const DeliverySettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M128 32l224 0c17.7 0 32 14.3 32 32l0 320-133.5 0c-13.2-37.3-48.7-64-90.5-64c-24.6 0-47 9.2-64 24.4L96 288l-32 0 0 96 0 32c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-16 0 0-148.2c0-11.5-4.1-22.5-11.6-31.2l-78.7-91.8C508.6 102.1 495.3 96 481.3 96L416 96l0-32c0-35.3-28.7-64-64-64L128 0C92.7 0 64 28.7 64 64l0 32L16 96c-8.8 0-16 7.2-16 16s7.2 16 16 16l224 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L96 96l0-32c0-17.7 14.3-32 32-32zM570.9 224L416 224l0-96 65.3 0c4.7 0 9.1 2 12.1 5.6L570.9 224zM416 256l160 0 0 128-5.5 0c-13.2-37.3-48.7-64-90.5-64c-24.6 0-47 9.2-64 24.4l0-88.4zM96 416a64 64 0 1 1 128 0A64 64 0 1 1 96 416zm384-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM48 160c-8.8 0-16 7.2-16 16s7.2 16 16 16l224 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L48 160zM16 224c-8.8 0-16 7.2-16 16s7.2 16 16 16l224 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L16 224z"
    />
  </Svg>
);
export const CountryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7l-172 0c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320l183.4 0c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64l-183.4 0c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160l172 0c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64l90.8 0c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64L380 192zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3l83.8 0zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160l83.7 0zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64l90.8 0c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64l-90.8 0zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3l-83.8 0c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3l-83.7 0c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z"
    />
  </Svg>
);
export const PhoneIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="#9ca3af" d="M301.7 367.6l-21.3-12.3c-51.4-29.6-94.1-72.4-123.7-123.7l-12.3-21.3 17.3-17.3 40.7-40.7L156 36.2 32 58.7 32 64c0 229.7 186.3 416 416 416l5.3 0 22.5-124L359.7 309.5 319 350.2l-17.3 17.4zM352 272l160 64L480 512l-32 0C200.6 512 0 311.4 0 64L0 32 176 0l64 160-55.6 55.6c26.8 46.5 65.5 85.2 112 112L352 272z" />
  </Svg>
);

export const MessageBox = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="#9ca3af" d="M64 96c-17.7 0-32 14.3-32 32l0 39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9l0-39.9c0-17.7-14.3-32-32-32L64 96zM32 207.6L32 384c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-176.4L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
  </Svg>
);
export const CelenderIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="#a0a7b3" d="M112 0c8.8 0 16 7.2 16 16l0 48 192 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 32 0c35.3 0 64 28.7 64 64l0 32 0 32 0 256c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192l0-32 0-32C0 92.7 28.7 64 64 64l32 0 0-48c0-8.8 7.2-16 16-16zM416 192L32 192l0 256c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32l0-256zM384 96L64 96c-17.7 0-32 14.3-32 32l0 32 384 0 0-32c0-17.7-14.3-32-32-32z" />
  </Svg>
);
export const SuitcaseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="#9ca3af" d="M160 48l0 48 192 0 0-48c0-8.8-7.2-16-16-16L176 32c-8.8 0-16 7.2-16 16zM128 96l0-48c0-26.5 21.5-48 48-48L336 0c26.5 0 48 21.5 48 48l0 48 64 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 160c0-35.3 28.7-64 64-64l64 0zm240 32l-224 0-16 0 0 320 256 0 0-320-16 0zM64 128c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l32 0 0-320-32 0zM448 448c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-32 0 0 320 32 0z" />
  </Svg>
);
export const SthethascopeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path d="M127 10.4c3.1 8.3-1.1 17.5-9.4 20.6L74.4 47.2C68.1 49.5 64 55.5 64 62.2L64 192c0 70.7 57.3 128 128 128s128-57.3 128-128l0-129.8c0-6.7-4.1-12.6-10.4-15L266.4 31c-8.3-3.1-12.5-12.3-9.4-20.6s12.3-12.5 20.6-9.4l43.2 16.2c18.7 7 31.1 24.9 31.1 44.9L352 192c0 83-63.1 151.2-144 159.2l0 .8c0 70.7 57.3 128 128 128s128-57.3 128-128l0-98c-27.6-7.1-48-32.2-48-62c0-35.3 28.7-64 64-64s64 28.7 64 64c0 29.8-20.4 54.9-48 62l0 98c0 88.4-71.6 160-160 160s-160-71.6-160-160l0-.8C95.1 343.2 32 275 32 192L32 62.2c0-20 12.4-37.9 31.1-44.9L106.4 1c8.3-3.1 17.5 1.1 20.6 9.4zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
  </Svg>
);
export const ForkKniefIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#6b7280"
      d="M64 9.7L273.7 234.4 251.1 257 42.9 34C38.5 49 32 77.1 32 112c0 41 12.6 72.2 36.6 104.6c21 28.3 50.1 56.9 87.3 93.2c0 0 0 0 0 0s0 0 0 0c6.6 6.4 13.4 13.1 20.5 20.1c0 0 0 0 0 0l.1 .1 .8 .8-22.6 22.6-.8-.8c-6.8-6.7-13.5-13.3-20-19.6c0 0 0 0 0 0s0 0 0 0c-37-36.2-68.3-66.8-91-97.4C15.6 198.8 0 161.1 0 112C0 69.6 8.6 36.2 13.6 20.4C17.6 7.6 29.4 0 41.7 0C50.2 0 58.2 3.5 64 9.7zM484.3 506.9L316.6 327.3l22.6-22.6L507.7 485.1c6 6.5 5.7 16.6-.8 22.6s-16.6 5.7-22.6-.8zM468.9 6.5c5.2 7.1 3.7 17.1-3.4 22.4L351.1 112.7c-3.1 2.3-6.1 4.8-8.8 7.6l-1.6 1.6C327.5 135.2 320 153.2 320 172s7.5 36.8 20.8 50.1l13.1 13.1C367.2 248.5 385.2 256 404 256s36.8-7.5 50.1-20.8l1.6-1.6c2.7-2.7 5.3-5.7 7.6-8.8l83.8-114.3c5.2-7.1 15.2-8.7 22.4-3.4s8.7 15.2 3.4 22.4L489.1 243.8c-3.3 4.4-6.9 8.6-10.7 12.5l-1.6 1.6C457.5 277.2 431.3 288 404 288c-25 0-49.1-9.1-67.8-25.5L91.3 507.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L313.5 239.8C297.1 221.1 288 197 288 172c0-27.3 10.8-53.5 30.1-72.8l1.6-1.6c3.9-3.9 8.1-7.5 12.5-10.7L446.5 3.1c7.1-5.2 17.1-3.7 22.4 3.4zM508 37.5c5.8 6.7 5.1 16.8-1.5 22.6l-128 112c-6.7 5.8-16.8 5.1-22.6-1.5s-5.1-16.8 1.5-22.6l128-112c6.6-5.8 16.8-5.1 22.6 1.5zM538.5 68c6.6 5.8 7.3 15.9 1.5 22.6l-112 128c-5.8 6.7-15.9 7.3-22.6 1.5s-7.3-15.9-1.5-22.6l112-128c5.8-6.7 15.9-7.3 22.6-1.5z"
    />
  </Svg>
);
export const StethoscopeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="#9ca3af" d="M132.6 33.4l-15 5.6L64 59.1 64 192c0 70.7 57.3 128 128 128s128-57.3 128-128l0-132.9L266.4 39l-15-5.6 11.2-30 15 5.6 64 24L352 36.9 352 48l0 144c0 83-63.1 151.2-144 159.2l0 .8c0 70.7 57.3 128 128 128s128-57.3 128-128l0-98c-27.6-7.1-48-32.2-48-62c0-35.3 28.7-64 64-64s64 28.7 64 64c0 29.8-20.4 54.9-48 62l0 98c0 88.4-71.6 160-160 160s-160-71.6-160-160l0-.8C95.1 343.2 32 275 32 192L32 48l0-11.1L42.4 33l64-24 15-5.6 11.2 30zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
  </Svg>
);
export const FolderopenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#a8afb9"
      d="M448 160l0 32 32 0 0-32c0-35.3-28.7-64-64-64L298.5 96c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0 80 0c19.6 0 37.3-11.9 44.6-30.2l64-160c5.9-14.8 4.1-31.5-4.8-44.7S543.9 224 528 224l-400 0c-19.6 0-37.3 11.9-44.6 30.2L32 382.8 32 96c0-17.7 14.3-32 32-32l117.5 0c8.5 0 16.6 3.4 22.6 9.4l22.6-22.6L204.1 73.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1L416 128c17.7 0 32 14.3 32 32zM384 448L64 448c-5.3 0-10.3-2.6-13.2-7s-3.6-10-1.6-14.9l64-160c2.4-6.1 8.3-10.1 14.9-10.1l400 0c5.3 0 10.3 2.6 13.2 7s3.6 10 1.6 14.9l-64 160C476.4 444 470.5 448 464 448l-80 0z"
    />
  </Svg>
);
export const ClipboardlistIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      fill="#717885"
      d="M145.5 68c5.3-20.7 24.1-36 46.5-36s41.2 15.3 46.5 36l3.1 12L254 80l34 0 0 48-96 0-96 0 0-48 34 0 12.4 0 3.1-12zM192 0c-32.8 0-61 19.8-73.3 48L80 48 64 48l0 16 0 16L32 80 0 80l0 32L0 480l0 32 32 0 320 0 32 0 0-32 0-368 0-32-32 0-32 0 0-16 0-16-16 0-38.7 0C253 19.8 224.8 0 192 0zM320 144l0-32 32 0 0 368L32 480l0-368 32 0 0 32 0 16 16 0 112 0 112 0 16 0 0-16zM208 80a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM136 272a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm40-16l-16 0 0 32 16 0 96 0 16 0 0-32-16 0-96 0zm0 96l-16 0 0 32 16 0 96 0 16 0 0-32-16 0-96 0zm-64 40a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
    />
  </Svg>
);
export const ScaleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="#6b7280"
      d="M51.9 390.6c-12.5-12.5-12.5-32.8 0-45.3l39.4-39.4 41.4 41.4c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-41.4-41.4 41.4-41.4 41.4 41.4c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-41.4-41.4 41.4-41.4 41.4 41.4c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-41.4-41.4 41.4-41.4 41.4 41.4c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L305.9 91.3l39.4-39.4c12.5-12.5 32.8-12.5 45.3 0l69.5 69.5c12.5 12.5 12.5 32.8 0 45.3L166.6 460.1c-12.5 12.5-32.8 12.5-45.3 0L51.9 390.6zm46.9 92.1c25 25 65.5 25 90.5 0L482.7 189.3c25-25 25-65.5 0-90.5L413.3 29.3c-25-25-65.5-25-90.5 0L29.3 322.7c-25 25-25 65.5 0 90.5l69.5 69.5z"
    />
  </Svg>
);
export const CheckIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z" />
  </Svg>
);
export const OnlineIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path fill="currentColor" d="M27.3 184.4C102.4 110 205.8 64 320 64s217.6 46 292.7 120.4c6.3 6.2 16.4 6.2 22.6-.1s6.2-16.4-.1-22.6C554.3 81.5 442.9 32 320 32S85.7 81.5 4.7 161.7c-6.3 6.2-6.3 16.3-.1 22.6s16.3 6.3 22.6 .1zM320 224c70.5 0 134.4 28.5 180.7 74.7c6.3 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6c-52.1-51.9-123.9-84-203.3-84s-151.2 32.1-203.3 84c-6.3 6.2-6.3 16.4 0 22.6s16.4 6.3 22.6 0C185.6 252.5 249.5 224 320 224zm0 160a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96a64 64 0 1 0 0-128 64 64 0 1 0 0 128z" />
  </Svg>
);
export const OfflineIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M3.4 6.1C8.9-.8 19-2 25.9 3.4l608 480c6.9 5.5 8.1 15.5 2.6 22.5s-15.5 8.1-22.5 2.6L6.1 28.6C-.8 23.1-2 13 3.4 6.1zm394.7 230l-54.7-43.2c70 5.6 133 36.3 179.9 83.1c6.3 6.2 6.3 16.4 0 22.6s-16.4 6.3-22.6 0c-28.4-28.3-63.5-50-102.6-62.5zM208.5 249.5c-25.8 12.5-49.1 29.2-69.2 49.2c-6.3 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6c19-18.9 40.6-35.2 64.2-48.3l27.6 21.8zM201.7 81.1L171.5 57.2C218 40.9 267.9 32 320 32c122.9 0 234.3 49.5 315.3 129.7c6.3 6.2 6.3 16.3 .1 22.6s-16.3 6.3-22.6 .1C537.6 110 434.2 64 320 64c-41.1 0-80.8 6-118.3 17.1zM48.8 123.4l26 20.5C57.9 156.2 42 169.8 27.3 184.4c-6.3 6.2-16.4 6.2-22.6-.1s-6.2-16.4 .1-22.6c13.8-13.7 28.5-26.5 44-38.3zM268.2 453.5a64 64 0 1 1 103.7-75 64 64 0 1 1 -103.7 75zM352 416a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
    />
  </Svg>
);
export const GenderIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path fill="#a0a7b3" d="M168.5 78.5a24 24 0 1 1 -17-44.9 24 24 0 1 1 17 44.9zm0-77.8A56 56 0 1 0 151.6 111.3 56 56 0 1 0 168.4 .6zM211.1 128L192 128l0 32s0 0 0 0l0 160s0 0 0 0l0 32s0 0 0 0l0 64s0 0 0 0l0 80 0 16 32 0s0 0 0 0s0 0 0 0l0-16 0-293.8 49.9 93.4 7.5 14.1 28.2-15.1-7.5-14.1-76.9-144-4.5-8.5-9.6 0zM126.3 263.2l7.2-14.3-28.6-14.3-7.2 14.3-64 128L22.1 400 48 400l48 0 0 96 0 16 32 0 0-16 0-96 32 0 0-32-32 0-32 0-22.1 0 52.4-104.8zM160 160l0-32-51.1 0-9.6 0-4.5 8.5-76.9 144-7.5 14.1 28.2 15.1 7.5-14.1L118.5 160l41.5 0z" />
  </Svg>
);
export const HeightIcon = ({ height }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 576 512">
    <path
      fill="#9ca3af"
      d="M113.8 39.9c-4.6-5.1-11-7.9-17.8-7.9s-13.3 2.9-17.8 7.9l-72 80c-8.9 9.9-8.1 25 1.8 33.9s25 8.1 33.9-1.8L72 118.5l0 274.9L41.8 359.9c-8.9-9.9-24-10.7-33.9-1.8s-10.7 24-1.8 33.9l72 80c4.6 5.1 11 7.9 17.8 7.9s13.3-2.9 17.8-7.9l72-80c8.9-9.9 8.1-25-1.8-33.9s-25-8.1-33.9 1.8L120 393.5l0-274.9 30.2 33.5c8.9 9.9 24 10.7 33.9 1.8s10.7-24 1.8-33.9l-72-80zM248 72c-13.3 0-24 10.7-24 24s10.7 24 24 24l304 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L248 72zm0 160c-13.3 0-24 10.7-24 24s10.7 24 24 24l304 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-304 0zM224 416c0 13.3 10.7 24 24 24l304 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-304 0c-13.3 0-24 10.7-24 24z"
    />
  </Svg>
);
export const WeightIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="#9ca3af"
      d="M112 176a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm320 0c0-28.8-6.9-56-19.2-80L448 96c17.7 0 32 14.3 32 32l0 320c0 17.7-14.3 32-32 32L64 480c-17.7 0-32-14.3-32-32l0-320c0-17.7 14.3-32 32-32l35.2 0C86.9 120 80 147.2 80 176c0 97.2 78.8 176 176 176s176-78.8 176-176zM391.8 64C359.5 24.9 310.7 0 256 0S152.5 24.9 120.2 64L64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-56.2 0zM320 224c0-20.6-9.7-39-24.9-50.7l23.6-55c3.5-8.1-.3-17.5-8.4-21s-17.5 .3-21 8.4l-23.6 55c-3.2-.5-6.4-.7-9.7-.7c-35.3 0-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64zm-64-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
    />
  </Svg>
);
export const AgeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="#9ca3af" d="M128 0l0 16 0 48 192 0 0-48 0-16 32 0 0 16 0 48 64 0 32 0 0 32 0 64 0 32 0 288 0 32-32 0L32 512 0 512l0-32L0 192l0-32L0 96 0 64l32 0 64 0 0-48L96 0l32 0zM416 192l-104 0 0 72 104 0 0-72zm0 104l-104 0 0 80 104 0 0-80zm0 112l-104 0 0 72 104 0 0-72zM280 376l0-80-112 0 0 80 112 0zM168 408l0 72 112 0 0-72-112 0zm-32-32l0-80L32 296l0 80 104 0zM32 408l0 72 104 0 0-72L32 408zm0-144l104 0 0-72L32 192l0 72zm136 0l112 0 0-72-112 0 0 72zM416 96L32 96l0 64 384 0 0-64z" />
  </Svg>
);
export const VideoIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="currentColor" d="M64 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l256 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32L64 96zM0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 47.2 0 161.6 0 47.2c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM519.4 411.3L416 354.4l0-36.5 118.8 65.4c.9 .5 1.9 .8 3 .8c3.4 0 6.2-2.8 6.2-6.2l0-243.6c0-3.4-2.8-6.2-6.2-6.2c-1 0-2.1 .3-3 .8L416 194.1l0-36.5 103.4-56.9c5.6-3.1 12-4.7 18.4-4.7c21.1 0 38.2 17.1 38.2 38.2l0 243.6c0 21.1-17.1 38.2-38.2 38.2c-6.4 0-12.8-1.6-18.4-4.7z" />
  </Svg>
);

export const GetCustomIcon = ({ icon }) => {
  const trimmedIcon = icon && typeof icon === "string" ? icon.trim() : "";
  switch (trimmedIcon) {
    case "video":
      return <VideoIcon />;
    case "offline":
      return <OfflineIcon />;
    case "online":
      return <OnlineIcon />;
    case "check":
      return <CheckIcon />;
    case "country":
      return <CountryIcon />;
    case "delivery-settings":
      return <DeliverySettingsIcon />;
    case "message-settings":
      return <MessageSettingsIcon />;
    case "book-appointment":
      return <BookAppointmentIcon />;
    case "activity-log":
      return <ActivityLogIcon />;
    case "user-log":
      return <UserLogIcon />;
    case "inventory-management":
      return <InventoryManagementIcon />;
    case "dispatch-settings":
      return <DispatchSettingsIcon />;
    case "order-management":
      return <OrderManagementIcon />;
    case "ingredient":
      return <IngredientIcon />;
    case "meal-plans":
      return <MealPlansIcon />;
    case "food-exchange-calorie":
      return <FoodExchangeCalorieIcon />;
    case "food-exchange-serve":
      return <FoodExchangeServeIcon />;
    case "calorie-management":
      return <CalorieManagementIcon />;
    case "cuisine-category":
      return <CuisineCategoryIcon />;
    case "recipe-category":
      return <RecipeCategoryIcon />;
    case "recipes":
      return <RecipesIcon />;
    case "week-menu":
      return <WeekMenuIcon />;
    case "redeem-coupon":
      return <RedeemCouponIcon />;
    case "pen":
      return <PenIcon />;
    case "back":
      return <BackIcon />;
    case "ArrowRightDoubleIcon":
      return <ArrowRightDoubleIcon />;
    case "arrowRight":
      return <ArrowRightIcon />;
    case "ArrowLeft":
      return <ArrowLeft />;
    case "ArrowDoubleLeft":
      return <ArrowDoubleLeft />;
    case "deleted":
      return <DeletedIcon />;
    case "preparation":
      return <PreparingIcon />;
    case "phone":
      return <PhoneIcon />;
    case "celender":
      return <MessageBox />;
    case "SthethascopeIcon":
      return <SthethascopeIcon />;
    case "ForkKniefIcon":
      return <ForkKniefIcon />;
    case "StethoscopeIcon":
      return <StethoscopeIcon />;
    case "FolderopenIcon":
      return <FolderopenIcon />;
    case "ClipboardlistIcon":
      return <ClipboardlistIcon />;
    case "ScaleIcon":
      return <ScaleIcon />;
    case "GenderIcon":
      return <GenderIcon />;
    case "HeightIcon":
      return <HeightIcon />;
    case " weightIcon":
      return <WeightIcon />;
    case "AgeIcon":
      return <AgeIcon />;
    default:
      // console.log("missing icon", icon);
      return <DefaultIcon />;
  }
};
