import { lazy } from "react";
import page404 from "../pages/page404";
const dashboard = lazy(() => import("../pages/dashboard"));
const memberData = lazy(() => import("../pages/user/member/memberData"));
const RenderSubPage = (element, content) => {
  if (content) {
    return content;
  } else {
    switch (element.page) {
      case "member":
        return memberData;
      case "dashboard":
        return dashboard;
      default:
        return page404;
    }
  }
};

export default RenderSubPage;
